import {
  LiveAutocompleDisplayFunction,
  LiveAutocompleteOptionDisabledBindFunction,
  LiveAutocompleteOptionDisplayBindFunction,
  LiveAutocompleteOptionModelIconFunction,
  LiveAutoCompleteOptionsConfigModel,
  LiveAutocompleteOptionValueBindFunction,
  LiveAutocompletePrefixIconFunction,
} from '@roadrecord/live-auto-complete';
import { isNil, isString } from '@roadrecord/type-guard';
import { DEFAULT_VEHICLE_ICON } from '@roadrecord/vehicle/common';
import { VehicleUsModel } from '@roadrecord/vehicle/model/us';

const getIconNameFromUrl = (url: string): string => {
  if (url === undefined) {
    return '';
  } else {
    return url.split('/').pop().split('.').shift();
  }
};

const laVehicleOptionDisabledBindFn: LiveAutocompleteOptionDisabledBindFunction<VehicleUsModel> = (
  model: VehicleUsModel,
  selectedModel: VehicleUsModel
) => model.id === selectedModel.id;
const laVehicleOptionDisplayFn: LiveAutocompleteOptionDisplayBindFunction<VehicleUsModel> = (model: VehicleUsModel) => model.plate_number;
const laVehicleOptionValueBindFn: LiveAutocompleteOptionValueBindFunction<VehicleUsModel> = (model: VehicleUsModel) =>
  !isNil(model) ? model.id : null;
const laVehiclePrefixIconFn: LiveAutocompletePrefixIconFunction<VehicleUsModel> = (model: VehicleUsModel) =>
  isString(model) ? undefined : getIconNameFromUrl(model.icon);
const laVehicleOptionModelIconFn: LiveAutocompleteOptionModelIconFunction<VehicleUsModel> = (model: VehicleUsModel) => ({
  icon: getIconNameFromUrl(model.icon),
});
const laVehicleDisplayFn: LiveAutocompleDisplayFunction<VehicleUsModel> = (model: VehicleUsModel) => model.plate_number;

export const laVehicleOptionsConfig = new LiveAutoCompleteOptionsConfigModel({
  optionDisabledBindFn: laVehicleOptionDisabledBindFn,
  optionDisplayFn: laVehicleOptionDisplayFn,
  optionValueBindFn: laVehicleOptionValueBindFn,
  optionModelIconFn: laVehicleOptionModelIconFn,
  displayFn: laVehicleDisplayFn,
  prefixIconFn: laVehiclePrefixIconFn,
  validatorMessages: [{ errorKey: 'required', translateKey: 'VEHICLE.LIVE_AUTO_COMPLETE.VALIDATOR.REQUIRED' }],
  label: 'VEHICLE.LIVE_AUTO_COMPLETE.LABEL',
  placeholder: 'VEHICLE.LIVE_AUTO_COMPLETE.PLACEHOLDER',
});
