import { Injectable } from '@angular/core';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { AbstractEntityService, HttpListResponseModel } from '@roadrecord/utils';
import { HttpClient } from '@angular/common/http';
import { isNil, isString } from '@roadrecord/type-guard';
import { Observable } from 'rxjs';

@Injectable()
export abstract class LicensedVehicleService<MODEL extends VehicleModel> extends AbstractEntityService<
  HttpListResponseModel<MODEL>,
  MODEL
> {
  constructor(http: HttpClient) {
    super('payment-manager/licensed-vehicle', 'plate_number', http);
  }

  getToString(model: MODEL): string {
    return model.plate_number;
  }

  getModelIdValue(model: MODEL): any {
    if (!isNil(model) && model.hasOwnProperty('id')) {
      return model.id;
    }
    return undefined;
  }

  get(id: number, params?: string, expand = 'departure,departure.trip_reason,vehicle_owner,vehicle_owner.trip_reason'): Observable<MODEL> {
    if (!isString(params)) {
      params = `expand=${expand}`;
    } else {
      params += `&expand=${expand}`;
    }
    return super.get(id, params);
  }
}
