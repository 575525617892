import { FINALIZATION_OF_ROUTE_IMPORT_WORKER } from '@roadrecord/finalization-of-route/worker';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule, DOCUMENT, Location, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import { Inject, Injector, NgModule, NgZone } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { Actions, NgxsModule, Store } from '@ngxs/store';
import { BaseAppModule } from '@roadrecord/app-base';
import { APP_HOTKEYS, AppLayoutModule, MENU_CONFIG_TOKEN } from '@roadrecord/app-layout/common';
import { ApplicationSettingsState } from '@roadrecord/application-settings/state';
import { BatchRequestModule, CheckModifiedMVPFormGuard, IS_WEBADMIN } from '@roadrecord/utils';
import {
  ClientVersionInterceptorService,
  customTranspiler,
  EN_US_MAT_DATE_FORMATS,
  EN_US_MAT_MOMENT_TIME_FORMATS,
  FIRST_STEPS_PATH,
  FIRST_STEPS_STEPPING_PATH,
  getContentSecurityPolicyNonceFromDom,
  HeaderLangCodeInterceptorService,
  MatAutocompleteConfigProvider,
  MatDialogConfigProvider,
  matPaginatorIntlFactory,
  translocoLoader,
  USER_ROUTE_PATH,
  UserCompanyMeState,
  ViewportService,
  WINDOW,
} from '@roadrecord/common/common';
import { CompanyContextState, STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { FirstStepsState } from '@roadrecord/first-steps/common';
import { FragmentPresenterModule } from '@roadrecord/fragment-dialog';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { I18nState } from '@roadrecord/i18n-state';
import { IdleState } from '@roadrecord/idle-detect';
import { MessageDialogModule, MessageDialogService } from '@roadrecord/message-dialog';
import { PreferencesState } from '@roadrecord/preferences-state/common';
import { SwUpdatesService } from '@roadrecord/service-worker';
import {
  AuthState,
  RRStorageEngine,
  RRStorageRouterExcludePathsToken,
  StateRefreshInterceptor,
  TokenInterceptor,
  UserState,
} from '@roadrecord/user/common';
import { MOMENT } from 'angular-calendar';
import { HotkeyModule } from 'angular2-hotkeys';
import moment from 'moment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appHotkeys } from './config/app.hotkeys';
import { menuConfig } from './config/menu.config';
import {
  PeriodContextService,
  PeriodContextStateSelectorsService,
  PeriodicStateContextInterceptor,
} from '@roadrecord/period-context/common';
import { PeriodContextStateSelectorsUsService, PeriodContextUsService, PeriodContextUsState } from '@roadrecord/period-context/us';
import { PARTNER_ADRESS_FORMATTER, PARTNER_FROM_GEOCODING_COPY_FIELDS_TOKEN } from '@roadrecord/partner/model';
import {
  addressFormatter,
  chargingStationLiveAutoCompleteDiFactory,
  formFragmentDynamicConfig as partnerAddressFormFragmentDynamicConfig,
  headOfficeOrSiteLiveAutoCompleteDiFactory,
  PARTNER_FROM_GEOCODING_COPY_FIELDS_US,
  partnerLiveAutoCompleteDiFactory,
} from '@roadrecord/partner/us';
import {
  NotificationAnimationType,
  NotificationsService,
  SimpleNotificationsModule,
} from '@roadrecord/external-packages/angular2-notifications';
import { SystemMessagesState, SystemMessagesWorkerBridgeService } from '@roadrecord/system-messages';
import { SystemMessagesWorkerBridgeImplService } from './modules/firebase/system-messages-worker-bridge-impl.service';
import { DYNAMIC_FORM_CONFIG } from '@roadrecord/dynamic-form';
import { companyMemberNameFormFragmentDynamicConfig } from '@roadrecord/user/us';
import { NGX_MAT_DATE_FORMATS } from '@roadrecord/external-packages/angular-material-components/datetime-picker';
import { FUELING_IMPORT_WORKER, FUELING_SETTINGS } from '@roadrecord/fueling/common';
import { fuelingSettings } from '@roadrecord/fueling/us';
import {
  CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
  HEAD_OFFICE_OR_SITE_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
  PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
} from '@roadrecord/partner/live-auto-complete';
import { TranslocoUtilsModule } from '@roadrecord/transloco-utils';
import { getFinalizationOfRouteImportWorkerFactory } from './modules/finalization-of-route-import/get-finalization-of-route-import-worker';
import { PARTNER_IMPORT_WORKER } from '@roadrecord/partner/common';
import { getPartnerImportWorkerFactory } from './modules/partner-import/get-partner-import-worker';
import { UserSettingsState } from '@roadrecord/user/application-settings';
import { IntrojsState } from '@roadrecord/introjs/common';
import { BroadcastChannel } from 'broadcast-channel';
import { isNil, isNotEmptyString, isObject } from '@roadrecord/type-guard';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { LoadableModule } from '@roadrecord/external-packages/ngx-loadable';
import { NavToolbarModule } from '@roadrecord/nav-toolbar';
import { environment } from '@roadrecord/environment';
import { LicensedVehicleService, VehicleService } from '@roadrecord/vehicle/service/common';
import { FakeGoogleTagManagerService } from '@roadrecord/fake-google-tag-manager';
import { LicensedVehicleUsService, VehicleUsService } from '@roadrecord/vehicle/service/us';
import { getFuelingImportWorkerFactory } from './modules/fueling-import/get-fueling-import-worker';
// decimal pipe miatt kell, mert ezekbol szedi a locale adatokat
registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [AppComponent],
  imports: [
    GoogleTagManagerModule.forRoot({
      id: environment.tracking.gtag.code,
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    TranslocoModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerImmediately',
    }),
    AppRoutingModule,

    /* TODO https://github.com/brtnshrdr/angular2-hotkeys/issues/50
     * fordításnak utána kell nézni, hogyan lehet
     */
    HotkeyModule.forRoot({
      cheatSheetCloseEsc: true,
      disableCheatSheet: true,
      cheatSheetDescription: 'Segédlet megnyitása',
      cheatSheetCloseEscDescription: 'Segédlet bezárása',
    }),
    NgIdleKeepaliveModule.forRoot(),
    NgxsModule.forRoot(
      [
        I18nState,
        FirstStepsState,
        AuthState,
        UserState,
        UserSettingsState,
        UserCompanyMeState,
        ApplicationSettingsState,
        // mindig hamarabb kell betolteni mint a menuState-t!
        PeriodContextUsState,
        IdleState,
        PreferencesState,
        CompanyContextState,
        SystemMessagesState,
        IntrojsState,
      ],
      { developmentMode: ['local-dev', 'dev'].indexOf(environment.titlePreFix) > -1 }
    ),
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: !environment.log.enableStateLog,
    //   logger: !environment.log.printStateToConsole ? logHandlers : undefined
    // }),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'auth',
        'calendars',
        'grids',
        'partnerVehicleJoin',
        'partnerVehicleCopy',
        'vehiclePartnerJoin',
        'vehiclePartnerCopy',
        'vehicleDailyPermanentDestination',
        'user.settings',
        'i18n',
        'systemMessages',
      ],
    }),
    // WebSocketModule,
    // NgxsReduxDevtoolsPluginModule.forRoot({ disabled: ['local-dev', 'dev'].indexOf(environment.mode) === -1 }),
    NgxsSelectSnapshotModule.forRoot(),
    FragmentPresenterModule.forRoot(),
    // NgxsLoggerPluginModule.forRoot({ collapsed: true, disabled: ['local-dev', 'dev'].indexOf(environment.mode) === -1 }),
    // getNgxsLoggerProvider(),
    // NgxsLoggerPluginModule.forRoot({
    //   collapsed: ['teszt', 'dev'].indexOf(environment.titlePreFix) > -1,
    //   disabled: ['teszt', 'dev'].indexOf(environment.titlePreFix) === -1
    // }),NgIdleKeepaliveModule.forRoot()
    // NgxsRouterPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    // getFirebaseLoggingProviders(environment),
    BatchRequestModule,

    AppLayoutModule,
    MessageDialogModule.forRoot(),
    MatMomentDateModule,
    // app componentben hasznaljuk
    MatSnackBarModule,
    LoadableModule.forRoot({
      moduleConfigs: [
        {
          name: 'fueling-range-window-content',
          loadChildren: () =>
            import('libs/fueling-range/common/src/lib/window-content/window-content.module').then(mod => mod.WindowContentModule),
        },
        {
          name: 'mileage-list-window-content',
          loadChildren: () => import('libs/mileage/us/src/lib/window-content/window-content.module').then(mod => mod.WindowContentModule),
        },
        {
          name: 'finalization-of-route-import-list-window-content',
          loadChildren: () =>
            import('libs/finalization-of-route/common/src/lib/details/import/window-content/window-content.module').then(
              mod => mod.WindowContentModule
            ),
        },
        {
          name: 'fueling-import-list-window-content',
          loadChildren: () =>
            import('libs/fueling/common/src/lib/import/window-content/window-content.module').then(mod => mod.WindowContentModule),
        },
        {
          name: 'finalization-of-route-copy-days',
          loadChildren: () =>
            import('libs/finalization-of-route/common/src/lib/copy-days/window-content/window-content.module').then(
              mod => mod.WindowContentModule
            ),
        },
        {
          name: 'vehicle',
          loadChildren: () => import('libs/vehicle/us/src/lib/details/details-us.module').then(m => m.DetailsUsModule),
        },
        {
          name: 'register',
          loadChildren: () => import('libs/user/common/src/lib/authentication/layout/register/register.module').then(m => m.RegisterModule),
        },
      ] as any,
    }),
    TranslocoUtilsModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'right'],
      lastOnBottom: false,
      clickToClose: true,
      clickIconToClose: true,
      preventDuplicates: true,
      preventLastDuplicates: true,
      animate: NotificationAnimationType.Scale,
      pauseOnHover: true,
      showProgressBar: true,
      timeOut: 7000,
    }),
    NavToolbarModule,
  ],
  providers: [
    translocoLoader,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: environment.languages.availableLangs,
        prodMode: ['local-dev', 'dev', 'e2e'].indexOf(environment.mode) === -1,
        defaultLang: environment.languages.defaultLang,
        fallbackLang: environment.languages.fallbackLang,
        reRenderOnLangChange: true,
        flatten: {
          aot: ['local-dev', 'dev', 'e2e'].indexOf(environment.mode) === -1,
        },
      } as TranslocoConfig,
    },
    customTranspiler,
    {
      provide: MOMENT,
      useValue: moment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderLangCodeInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientVersionInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PeriodicStateContextInterceptor,
      multi: true,
    },
    {
      provide: STATE_PREFIX_TOKEN,
      useValue: 'rr',
    },
    {
      provide: UserCompanyMeState,
      useFactory: (http: HttpClient) => {
        return new UserCompanyMeState(http);
      },
      deps: [HttpClient],
    },
    {
      provide: FINALIZATION_OF_ROUTE_IMPORT_WORKER,
      useFactory: getFinalizationOfRouteImportWorkerFactory,
    },
    {
      provide: PARTNER_IMPORT_WORKER,
      useFactory: getPartnerImportWorkerFactory,
    },
    {
      provide: FUELING_IMPORT_WORKER,
      useFactory: getFuelingImportWorkerFactory,
    },
    {
      provide: SystemMessagesWorkerBridgeService,
      useClass: SystemMessagesWorkerBridgeImplService,
      deps: [NgZone, Store, NotificationsService, Actions],
    },
    {
      provide: IS_WEBADMIN,
      useValue: false,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: StateRefreshInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      // TODO hogy kell forditani ? ? ? ?
      provide: MatPaginatorIntl,
      useFactory: matPaginatorIntlFactory,
      deps: [TranslocoService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: null, // 'hu' // window.navigator.language || (window.navigator as any).userLanguage
    },
    {
      provide: MENU_CONFIG_TOKEN,
      useValue: menuConfig,
    },
    MatDialogConfigProvider,
    MatAutocompleteConfigProvider,
    {
      provide: WINDOW,
      useValue: window,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: RRStorageEngine,
      deps: [Injector],
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000, verticalPosition: 'top' } },
    {
      provide: RRStorageRouterExcludePathsToken,
      useValue: [`/${USER_ROUTE_PATH}/change-password`, `/${FIRST_STEPS_PATH}/${FIRST_STEPS_STEPPING_PATH}`],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' },
    },
    CheckModifiedMVPFormGuard,
    { provide: APP_HOTKEYS, useValue: appHotkeys },
    SwUpdatesService,
    // customTranspiler,
    { provide: VehicleService, useClass: VehicleUsService },
    { provide: LicensedVehicleService, useClass: LicensedVehicleUsService },
    { provide: PeriodContextService, useClass: PeriodContextUsService },
    { provide: PeriodContextStateSelectorsService, useClass: PeriodContextStateSelectorsUsService },
    { provide: PARTNER_FROM_GEOCODING_COPY_FIELDS_TOKEN, useValue: PARTNER_FROM_GEOCODING_COPY_FIELDS_US },
    { provide: DYNAMIC_FORM_CONFIG, multi: true, useValue: partnerAddressFormFragmentDynamicConfig },
    { provide: DYNAMIC_FORM_CONFIG, multi: true, useValue: companyMemberNameFormFragmentDynamicConfig },
    { provide: NGX_MAT_DATE_FORMATS, useValue: EN_US_MAT_MOMENT_TIME_FORMATS },
    { provide: MAT_DATE_FORMATS, useValue: EN_US_MAT_DATE_FORMATS },
    { provide: FUELING_SETTINGS, useValue: fuelingSettings },
    {
      provide: PARTNER_ADRESS_FORMATTER,
      useValue: addressFormatter,
    },
    {
      provide: PARTNER_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
      useFactory: partnerLiveAutoCompleteDiFactory,
    },
    {
      provide: CHARGING_STATION_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
      useFactory: chargingStationLiveAutoCompleteDiFactory,
    },
    {
      provide: HEAD_OFFICE_OR_SITE_LIVE_AUTO_COMPLETE_OPTIONS_CONFIG,
      useFactory: headOfficeOrSiteLiveAutoCompleteDiFactory,
    },
    { provide: 'googleTagManagerCSPNonce', useValue: getContentSecurityPolicyNonceFromDom() },
    ...(environment.tracking.gtag.enable
      ? [GoogleTagManagerService]
      : [{ provide: GoogleTagManagerService, useClass: FakeGoogleTagManagerService }]),
  ],
  bootstrap: [AppComponent],
})
export class AppModule extends BaseAppModule {
  constructor(
    translocoService: TranslocoService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) document: Document,
    @Inject(WINDOW) window: Window & { Cypress: any; store: any },
    @Inject(IS_WEBADMIN) isWebadmin: boolean,
    viewportService: ViewportService,
    store: Store,
    actions: Actions,
    location: Location,
    swUpdatesService: SwUpdatesService,
    breakpointObserver: BreakpointObserver,
    // lazy load service, is required mileage list hotkey
    messageDialogService: MessageDialogService,
    injector: Injector,
    router: Router
  ) {
    super(
      'UsAppModule',
      translocoService,
      matIconRegistry,
      domSanitizer,
      document,
      window,
      isWebadmin,
      viewportService,
      store,
      actions,
      location,
      swUpdatesService,
      breakpointObserver,
      messageDialogService,
      injector
    );

    // broadcastChannel -nek kell :(
    (window as any).process = {
      browser: true,
    };

    const globalBroadcastChannel = new BroadcastChannel('rr-app-channel', { webWorkerSupport: false });
    globalBroadcastChannel.addEventListener('message', event => {
      if (isObject(event) && !isNil(event.appId) && !isNil(event.cmd)) {
        switch (event.cmd) {
          case 'PING':
            globalBroadcastChannel.postMessage({ addressAppId: event.appId, cmd: 'ANSWER', callerCmd: 'PING', msg: 'PONG' });
            break;
          case 'GET_LOGGINED_USER_EMAIL':
            let msg = 'NOT_LOGGED_IN';
            if (store.selectSnapshot(AuthState.isLoggedIn)) {
              msg = store.selectSnapshot(AuthState.user).email;
            }
            globalBroadcastChannel.postMessage({
              addressAppId: event.appId,
              cmd: 'ANSWER',
              callerCmd: 'GET_LOGGINED_USER_EMAIL',
              msg: msg,
            });

            break;
          case 'LOGIN':
            if (isNotEmptyString(event.msg)) {
              window.location.replace(`${window.location.origin}/?user-jwt-token=${event.msg}`);
            }
            break;
        }
      }
    });
  }
}
