import { Injectable } from '@angular/core';
import { LicensedVehicleService } from '@roadrecord/vehicle/service/common';
import { VehicleUsModel } from '@roadrecord/vehicle/model/us';
import { BatchRequestModel, HttpListResponseModel } from '@roadrecord/utils';
import { environment } from '@roadrecord/environment';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Observable } from 'rxjs';

@Injectable()
export class LicensedVehicleUsService extends LicensedVehicleService<VehicleUsModel> {
  removeBatch(id: string | number): BatchRequestModel {
    return { method: 'delete', relative_url: `/${environment.baseApiEndpoint}/vehicles/${id}/` };
  }

  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue = ''
  ): Observable<HttpListResponseModel<VehicleUsModel>> {
    return this.getAllWithExtraQueryParams(sort, page, simpleAllFilterValue, { is_deleted: 'False' });
  }
}
