import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PeriodContextUsState } from '../period-context-us.state';
import { PeriodContextStateUsModel } from '../model/period-context-state-us.model';
import { PeriodContextUsModel } from '../model/period-context-us.model';
import { TripReportProcessTypeEnum } from '@roadrecord/trip-reason/model';

@Injectable()
export class PeriodContextStateSelectorsUsService extends PeriodContextStateSelectorsService<
  PeriodContextUsModel,
  PeriodContextStateUsModel
> {
  @Selector([PeriodContextUsState])
  runVehiclePull(state: PeriodContextStateUsModel): boolean {
    return super.runVehiclePull(state);
  }

  @Selector([PeriodContextUsState])
  loading(state: PeriodContextStateUsModel): boolean {
    return super.loading(state);
  }

  @Selector([PeriodContextUsState])
  state(state: PeriodContextStateUsModel): PeriodContextStateUsModel {
    return super.state(state);
  }

  @Selector([PeriodContextUsState])
  context(state: PeriodContextStateUsModel): PeriodContextUsModel {
    return super.context(state);
  }

  @Selector([PeriodContextUsState])
  hasContext(state: PeriodContextStateUsModel): boolean {
    return super.hasContext(state);
  }

  @Selector([PeriodContextUsState])
  viewDate(state: PeriodContextStateUsModel): Date {
    return super.viewDate(state);
  }

  @Selector([PeriodContextUsState])
  reportType(state: PeriodContextStateUsModel): TripReportProcessTypeEnum {
    return TripReportProcessTypeEnum.COMPANY;
  }

  @Selector([PeriodContextUsState])
  isCompany(state: PeriodContextStateUsModel): boolean {
    return true;
  }

  @Selector([PeriodContextUsState])
  isPrivate(state: PeriodContextStateUsModel): boolean {
    return false;
  }

  @Selector([PeriodContextUsState])
  isSelfEmployed(state: PeriodContextStateUsModel): boolean {
    return false;
  }
}
