import { Level } from '@roadrecord/logger';
import appTitleConfig from '../../../../../apps/app-title.json';
import { versionUsTest as version } from '../../../../../apps/scripts/version-reader';
import urlsConfig from '../../../../../apps/urls.json';
import { EnvironmentInterface } from '../environment/environment.interface';
import { AppTypeEnum } from '../app-type.enum';
import { LOCALES } from '../locales.enum';
import { RRSourceEnum } from '../rr-source.enum';

const baseUrl = urlsConfig.usTest; // '//api-test.roadrecord.hu'
const webadminBaseUrl = '//api.test.mileagewise.com/webadmin';
const baseApiEndpoint = 'api/v1';
const baseApi2Endpoint = 'api/v2';
const baseApi3Endpoint = 'api/v3';
export const environment: EnvironmentInterface = {
  languages: {
    defaultLang: LOCALES.EN_US,
    availableLangs: [LOCALES.EN_US],
    fallbackLang: LOCALES.EN_US,
  },
  idleTimeoutTime: 30 * 60,
  appNameTrasnalteKey: appTitleConfig.usTest, // 'APP_NAME.TEST',
  production: true,
  baseUrl,
  baseApiEndpoint,
  baseApi2Endpoint,
  baseApi3Endpoint,
  apiUrl: `${baseUrl}/${baseApiEndpoint}/`,
  api2Url: `${baseUrl}/${baseApi2Endpoint}/`,
  api3Url: `${baseUrl}/${baseApi3Endpoint}/`,
  webadminApiUrl: `${webadminBaseUrl}/${baseApiEndpoint}/`,
  webadminApi2Url: `${webadminBaseUrl}/${baseApi2Endpoint}/`,
  ng1Url: '//api.test.mileagewise.com/',
  baseHref: '/',
  primaryColor: '#B24C63',
  titlePreFix: 'teszt',
  dispalyLog: true,
  VERSION: `${version.date}-${version.config.versionPlaceholder}-${version.dayCounter}`,
  partnerImportSampleUrl: 'https://static-test.mileagewise.com/downloadable/client_import_sample.xlsx',
  fuelingImportSampleUrl: 'https://static-test.mileagewise.com/downloadable/refueling_import_sample.xlsx',
  firebaseLogging: {
    apiKey: 'AIzaSyD3RjyFaw_aYUkaQHcPHvbuf1aSsE6oK5Y',
    authDomain: 'roadrecordtestlogger.firebaseapp.com',
    databaseURL: 'https://roadrecordtestlogger.firebaseio.com',
    projectId: 'roadrecordtestlogger',
    storageBucket: 'roadrecordtestlogger.appspot.com',
    messagingSenderId: '79770178244',
  },
  serviceWorker: true,
  useCaptcha: true,
  captchaCode: '6LdsHiUUAAAAANvKJpVRvD8CHlI1WhOlJTMHJ1xM',
  mode: 'test-site',
  tracking: {
    gtag: {
      enable: true,
      code: 'GTM-PKL5D8L',
    },
  },
  fbUpdateUrl: 'https://mw-test-11705.firebaseio.com/versions/us-frontend-test.json',
  fbRemoteConfigUrl: '',
  fbRemoteMockHttpConfigUrl: '',
  remoteConfig: {
    enable: true,
    mockHttp: {
      enable: true,
    },
  },
  ws: {
    url: 'wss://ws.dev.roadrecord.hu/ws',
    cookieDomain: '.roadrecord.hu',
    secure: true,
    checkCookieName: 'token_authorization',
    cookieMaxLifeTime: 1, // minutes
  },
  ngswBypass: true,
  log: {
    source: RRSourceEnum.FRONTEND,
    remoteEnable: false,
    consoleEnable: true,
    enableStateLog: false,
    printStateToConsole: false,
    levels: {
      console: Level.LOG,
      remote: Level.LOG,
    },
  },
  featureFlags: [],
  finalizationOfRouteImportSampleUrl: 'https://static-test.mileagewise.com/downloadable/fixed_trips_import_sample.xlsx',

  switchToUserUrl: 'https://ttadmin.mileagewise.com',
  appType: AppTypeEnum.US,
  ms: {
    pdfPrinter: 'https://pdf-printer.test.mileagewise.com',
    excelOrCsvPrinter: 'https://excel-printer.test.mileagewise.com/',
  },
  fbConfig: {
    apiKey: 'AIzaSyCAryn1jX8C5HBYeNlo80n2xVZ8Fd--8bU',
    authDomain: 'mw-test-11705.firebaseapp.com',
    databaseURL: 'https://mw-test-11705.firebaseio.com',
    projectId: 'mw-test-11705',
    storageBucket: 'mw-test-11705.appspot.com',
    messagingSenderId: '850325943607',
    appId: '1:850325943607:web:c808ecba1c3c8c8f43d6b2',
    measurementId: 'G-HGKVYQ6ZX4',
  },
  osmMapUrl: 'https://api-online.mileagewise.com/styles/osm-liberty/style.json',
  map: {
    center: [-98.5795, 39.828167],
    defaultZoom: 3,
    scaleUnit: 'imperial',
  },
  badge: {
    android: {
      url: 'https://play.google.com/store/apps/details?id=com.mileagewise.mileagewiseonline',
    },
    ios: {
      url: 'https://apps.apple.com/app/id1526654173',
    },
  },
  apiUrls: {
    wpFeatureList: 'https://mwtest.mileagewise.com/wp-ajax.php?action=get-features-list',
  },
};
