import { PartnerAddressFormatterReturnType } from '@roadrecord/partner/model';

export function getPartnerListPostcodeAndAddressColumns(partnerAddressFormatter: PartnerAddressFormatterReturnType) {
  return [
    {
      name: 'address',
      displayNameTranslateKey: 'PARTNER.LIST.COLUMN.ADDRESS',
      cellContentTemplateCallback: model => partnerAddressFormatter(model, false),
    },
    {
      name: 'postcode',
      displayNameTranslateKey: 'PARTNER.LIST.COLUMN.POSTCODE',
      cellContentTemplateCallback: model => `${model.address.postcode}`,
    },
  ];
}
