import { RRFormWithDynamicFormBottomSheetConfig } from '@roadrecord/utils';
import { Injector } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { dynamicFormConfigHelper } from '@roadrecord/dynamic-form';
import { FormControl } from '@angular/forms';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

export function searchMissingFieldConfig(bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<VehicleModel>, injector: Injector) {
  const realFuelControl = new FormControl();
  const realFuelUnitControl = new FormControl('MPG');

  const field: FormlyFieldConfig[] = [
    {
      type: 'flex-layout',
      templateOptions: {
        cssClass: 'w-100',
        flex: {
          fxLayout: 'column',
          fxLayout__gt_sm: 'row',
          fxLayoutGap: '1em',
          fxFlex: '100%',
        },
      },
      fieldGroup: [
        {
          type: 'us-real-fuel-field',
          key: 'real_fuel',
          templateOptions: {
            fuelType: bottomSheetData.data.fuel_type,
            required: true,
            transloco: true,
            label: 'VEHICLE.DETAILS.DATA_FORM.LABEL.REAL_FUEL_CONSUMPTION',
            placeholder: 'VEHICLE.DETAILS.DATA_FORM.LABEL.REAL_FUEL_CONSUMPTION',
            flex: {
              fxFlex: '100%',
            },
            numberMaskOptions: {
              mask: globalImaskNumberConfigGenerator({ max: 999 }),
              __disable_deep_copy__: true,
            },
          },
        },
      ],
    },
  ];
  const config = dynamicFormConfigHelper('x' as any, field).config(injector);
  config[0].formControl = realFuelControl;
  config[0].fieldGroup[0].templateOptions.realFuelUnitControl = realFuelUnitControl;
  return config;
}

const translatePrefix = 'FUELING.DETAILS.REAL_FUEL_BOTTOM_SHEET.';
export const searchMissingFieldTranslate: { title: string; content: string } = {
  title: `${translatePrefix}TITLE`,
  content: `${translatePrefix}CONTENT`,
};
