import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Host, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';
import { MenuService } from '@roadrecord/app-layout/common';
import {
  CheckModifiedMVPFormGuard,
  ENTITY_SERVICE_TOKEN,
  HasPresenterDeletePlugin,
  HasPresenterSavePluginController,
  PRESENTER_COMPONENT,
  PRESENTER_DELETE_OPTIONS,
  PRESENTER_PLUGINS,
  PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
  PRESENTER_STATE_CONTROLLER_PROVIDER,
  PRESENTER_STATE_DEFAULT_PROVIDERS,
  PresenterDeleteOptions,
  PresenterSavePluginOptionsModel,
  PresenterStateController,
  SearchMissingFieldAndShowNotificationFields,
  VIEW_COMPONENT,
  VIEW_MODEL_PLUGIN_RESET_STATE,
} from '@roadrecord/utils';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { isNil } from '@roadrecord/type-guard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, ReplaySubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FuelingService } from '../../../../../common/src/lib/fueling.service';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FuelingModel } from '../../../../../common/src/lib/model/fueling.model';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { DataFormComponent } from '../../../../../common/src/lib/details/data-form/data-form.component';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { ChargingStationService } from '@roadrecord/partner/common';
import { NotificationsService } from '@roadrecord/external-packages/angular2-notifications';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
// tslint:disable-next-line:nx-enforce-module-boundaries
import {
  CommonDetailsComponent,
  presenterDeleteOptionServiceRemoveCb,
  presenterSavePluginOptions,
} from '../../../../../common/src/lib/details/details/details.common';
import { PayoffTypeEnum } from '@roadrecord/vehicle/model/us';
import { VehicleService } from '@roadrecord/vehicle/service/common';

@UntilDestroy()
@Component({
  selector: 'rr-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChargingStationService,
    FuelingService,
    {
      provide: PRESENTER_DELETE_OPTIONS,
      useValue: {
        slideToggleText: 'FUELING.DELETE_DIALOG_RECALCULATION_TOGGLE',
        serviceRemoveCb: presenterDeleteOptionServiceRemoveCb,
      } as PresenterDeleteOptions<FuelingService>,
    },
    { provide: ENTITY_SERVICE_TOKEN, useExisting: FuelingService },
    { provide: PRESENTER_COMPONENT, useValue: forwardRef(() => DetailsComponent) },
    { provide: VIEW_COMPONENT, useValue: forwardRef(() => DataFormComponent) },
    {
      provide: PRESENTER_SAVE_PLUGIN_OPTIONS_TOKEN,
      useValue: new PresenterSavePluginOptionsModel({
        ...presenterSavePluginOptions,
        // Function a this miatt szukseges
        sendSetQueryParams: function () {
          if (this.smrMode === true) {
            return { reduced_dataset: this.dataForm.reducedDatasetControl.value };
          }
          return undefined;
        },
      }),
    },
    {
      provide: VIEW_MODEL_PLUGIN_RESET_STATE,
      useValue: { mileage_calculation: false },
    },
    { provide: PRESENTER_PLUGINS, useValue: PRESENTER_STATE_DEFAULT_PROVIDERS },
    PRESENTER_STATE_CONTROLLER_PROVIDER,
  ],
})
/*AfterViewInit*/
export class DetailsComponent
  extends CommonDetailsComponent
  implements
    SearchMissingFieldAndShowNotificationFields<VehicleModel>,
    OnInit,
    HasPresenterSavePluginController<FuelingModel, DataFormComponent>,
    HasPresenterDeletePlugin {
  readonly isNew$: Observable<boolean>;
  readonly editModel$: ReplaySubject<any>;
  readonly loading$: ReplaySubject<boolean>;
  @ViewChild('dataForm', { static: true })
  dataForm: DataFormComponent;
  smrMode: boolean;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Host() presenterStateController: PresenterStateController<any, any>,
    cdr: ChangeDetectorRef,
    translocoService: TranslocoService,
    @Inject(DOCUMENT) document: Document,
    matSnackBar: MatSnackBar,
    fuelingService: FuelingService,
    checkModifiedMVPFormGuard: CheckModifiedMVPFormGuard,
    store: Store,
    menuService: MenuService,
    messageDialogService: MessageDialogService,
    periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
    notificationsService: NotificationsService,
    bottomSheet: MatBottomSheet,
    vehicleService: VehicleService<any>,
    location: Location
  ) {
    super(
      router,
      route,
      presenterStateController,
      cdr,
      translocoService,
      document,
      matSnackBar,
      fuelingService,
      checkModifiedMVPFormGuard,
      store,
      menuService,
      messageDialogService,
      periodContextStateSelectorsService,
      notificationsService,
      bottomSheet,
      vehicleService,
      location
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.smrMode = this.route.snapshot.data.periodContextVehicle.payoff_type === PayoffTypeEnum.SMR;
  }

  searchMissingFieldAndShowNotification__GetData(): Observable<{ data: VehicleModel } & any> {
    return this.route.data
      .pipe(untilDestroyed(this))
      .pipe(
        switchMap(routeData =>
          !isNil(routeData.periodContextVehicle) &&
          (isNil(routeData.periodContextVehicle.real_fuel) || routeData.periodContextVehicle.real_fuel === 0)
            ? of({ data: routeData.periodContextVehicle })
            : of(null)
        )
      );
  }

  protected onModifyInit(editModel: any): any {
    if (this.smrMode) {
      editModel.reduced_dataset = editModel.meta.reduced_dataset;
      this.dataForm.form.get('reduced_dataset').patchValue(editModel.reduced_dataset);
      delete editModel.meta;
    }

    return editModel;
  }

  protected getSaveHttpParams(): any {
    return { reduced_dataset: this.smrMode };
  }

  /**
   * Init reduced_dataset control in smrMode
   */
  protected newCheckIsNewThen() {
    if (this.smrMode === true) {
      this.dataForm.form.get('reduced_dataset').patchValue(false);
    }
  }
}
