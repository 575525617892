<rr-password-suffix *ngIf="showPassword" [formlyFields]="formlyFields" passwordGroupId="3"></rr-password-suffix>
<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="1em" class="wid100">
  <rr-first-stepper-help-box [helpText]="helpText" layoutName="REGISTER"></rr-first-stepper-help-box>
  <mat-progress-spinner
    style="align-self: center"
    color="primary"
    mode="indeterminate"
    *ngIf="loaded === false; else formTpl"
  ></mat-progress-spinner>
  <ng-template #formTpl
    ><form [formGroup]="form" fxLayout="column" fxLayoutAlign="center stretch" novalidate>
      <mat-card class="form-card">
        <mat-card-content>
          <rr-formly-form class="w-100" [form]="form" [fields]="formlyFields" [model]="formModel" [options]="formOptions"></rr-formly-form>
        </mat-card-content>
        <mat-progress-bar color="warn" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        <mat-card-actions align="end">
          <rr-submit-button-with-error
            [disabled]="form.disabled || (form.submitted && form.invalid)"
            [formSubmitted]="form.submitted"
            [formInvalid]="form.invalid"
            buttonLabel="FIRST_STEPS.COMPANY_DATA.REGISTER_BUTTON"
            invalidTooltip="COMMON.VALIDATION.BUTTON"
            (clickButton)="onSubmit()"
          ></rr-submit-button-with-error>
        </mat-card-actions>
      </mat-card> </form
  ></ng-template>
</div>
