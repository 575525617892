import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { CapitalizeDirective } from '@roadrecord/common/common';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date): string {
    const momentDate = moment(value);
    return `${CapitalizeDirective.capitalizeText(momentDate.format('MMMM D, '))}${CapitalizeDirective.capitalizeText(
      momentDate.format('dddd')
    )}`;
  }
}
