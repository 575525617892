import { Injectable } from '@angular/core';
import { VehicleUsModel } from '@roadrecord/vehicle/model/us';
import { laVehicleOptionsConfig } from '@roadrecord/vehicle/autocomplete/us';
import { VehicleService } from '@roadrecord/vehicle/service/common';

@Injectable()
export class VehicleUsService extends VehicleService<VehicleUsModel> {
  protected loadLiveAutocompleteConfig(): void {
    this._liveAutocompleteConfig = laVehicleOptionsConfig;
  }
}
