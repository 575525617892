import { DynamicFormConfigEnum, dynamicFormConfigHelper, DynamicFormConfigInterface } from '@roadrecord/dynamic-form';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CapitalizeDirective } from '@roadrecord/common/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// TODO kipucolni amit nem hasznalunk

let firstNameSubscription: Subscription;
let lastNameSubscription: Subscription;

function generateFormlyFieldConfig(
  lastName: { key: string; matFormFieldClass: string; label: string; placeholder: string },
  firstName: { key: string; matFormFieldClass: string; label: string; placeholder: string }
): FormlyFieldConfig[] {
  return [
    {
      type: 'flex-layout',
      templateOptions: {
        cssClass: 'w-100',
        flex: {
          fxLayout: 'column',
          fxLayout__gt_sm: 'row',
          fxLayoutGap: '0em',
          fxLayoutGap__gt_sm: '1em',
        },
      },
      fieldGroup: [
        {
          type: 'input',
          key: firstName.key,
          focus: true,
          templateOptions: {
            matFormFieldClass: firstName.matFormFieldClass,
            required: true,
            minLength: 2,
            maxLength: 40,
            transloco: true,
            label: firstName.label,
            placeholder: firstName.placeholder,
            flex: {
              fxFlex: '100%',
              fxFlex__gt_sm: '50%',
            },
          },
          hooks: {
            onInit: ({ formControl }) => {
              return (firstNameSubscription = formControl.valueChanges
                .pipe(
                  filter(
                    () =>
                      /**
                       * ngx formly miatt, valamiert amikor a group-t disable-re rakjuk ez meg visszahivja es megbolonditja a group-t ami enable-nek jelzi magat ...
                       */
                      formControl.enabled
                  )
                )
                .subscribe(v => {
                  formControl.patchValue(CapitalizeDirective.capitalizeText(v), { emitEvent: false });
                }));
            },
            onDestroy: () => firstNameSubscription.unsubscribe(),
          },
        },
        {
          type: 'input',
          key: lastName.key,
          templateOptions: {
            matFormFieldClass: lastName.matFormFieldClass,
            required: true,
            minLength: 2,
            maxLength: 40,
            transloco: true,
            label: lastName.label,
            placeholder: lastName.placeholder,
            flex: {
              fxFlex: '100%',
              fxFlex__gt_sm: '50%',
            },
          },
          hooks: {
            onInit: ({ formControl }) =>
              (lastNameSubscription = formControl.valueChanges
                .pipe(filter(() => formControl.enabled))
                .subscribe(v => formControl.patchValue(CapitalizeDirective.capitalizeText(v), { emitEvent: false }))),
            onDestroy: () => lastNameSubscription.unsubscribe(),
          },
        },
      ],
    },
  ];
}

// const minlength = Validators.minLength(8);
// const maxlength = Validators.maxLength(20);
// const uppercaseCharacterRule = PasswordValidators.uppercaseCharacterRule(1);
// const lowercaseCharacterRule = PasswordValidators.lowercaseCharacterRule(1);
// const digitCharacterRule = PasswordValidators.digitCharacterRule(1);
// const noWhitespaceRequired = UniversalValidators.noWhitespace;

const registrationFragment = generateFormlyFieldConfig(
  { key: 'lastName', matFormFieldClass: 'x--last-name', label: 'USER.REGISTER.LAST_NAME', placeholder: 'USER.REGISTER.EX_LAST_NAME' },
  { key: 'firstName', matFormFieldClass: 'x--first-name', label: 'USER.REGISTER.FIRST_NAME', placeholder: 'USER.REGISTER.EX_FIRST_NAME' }
);
// registrationFragment.push({
//   type: 'flex-layout',
//   templateOptions: {
//     cssClass: 'w-100',
//     flex: {
//       fxLayout: 'column',
//       fxLayout__gt_sm: 'row',
//       fxLayoutGap: '0em',
//       fxLayoutGap__gt_sm: '1em',
//     },
//   },
//   fieldGroup: [
//     {
//       type: 'input',
//       key: 'email',
//       templateOptions: {
//         matFormFieldClass: 'x--email',
//         required: true,
//         transloco: true,
//         label: 'USER.REGISTER.EMAIL',
//         placeholder: 'USER.REGISTER.EX_EMAIL',
//         description: 'USER.REGISTER.HINT.EMAIL',
//         flex: {
//           fxFlex: '100%',
//         },
//       },
//       validators: {
//         normalEmailRule: {
//           expression: c => isNil(EmailValidators.normal(c)),
//         },
//       },
//     },
//   ],
// });
// registrationFragment.push({
//   type: 'flex-layout',
//   templateOptions: {
//     cssClass: 'w-100',
//     flex: {
//       fxLayout: 'column',
//       fxLayout__gt_sm: 'row',
//       fxLayoutGap: '0em',
//       fxLayoutGap__gt_sm: '1em',
//     },
//   },
//   fieldGroup: [
//     {
//       type: 'password',
//       key: 'password',
//       templateOptions: {
//         matFormFieldClass: 'x--password',
//         required: true,
//         transloco: true,
//         label: 'USER.REGISTER.PASSWORD',
//         placeholder: 'USER.REGISTER.PASSWORD',
//         description: 'COMMON.VALIDATION.PASSWORD_PATTERN',
//         flex: {
//           fxFlex: '100%',
//           fxFlex__gt_sm: '50%',
//         },
//         passwordType: 'password',
//       },
//       validators: {
//         minlength: {
//           expression: c => isNil(minlength(c)),
//         },
//         maxlength: {
//           expression: c => isNil(maxlength(c)),
//         },
//         uppercaseCharacterRule: {
//           expression: c => isNil(uppercaseCharacterRule(c)),
//         },
//         lowercaseCharacterRule: {
//           expression: c => isNil(lowercaseCharacterRule(c)),
//         },
//         digitCharacterRule: {
//           expression: c => isNil(digitCharacterRule(c)),
//         },
//         noWhitespaceRequired: {
//           expression: c => isNil(noWhitespaceRequired(c)),
//         },
//         mismatchedPasswords: {
//           expression: (c, field) => {
//             return isNil(mismatchedRePassword(field.form.get('password_confirmation'))(c));
//           },
//         },
//       },
//       expressionProperties: {
//         'templateOptions.hideDescription': (model: any, formState: any, field: FormlyFieldConfig) => {
//           return field.formControl.valid || (isString(field.formControl.value) && field.formControl.value.length > 0);
//         },
//       },
//     },
//     {
//       type: 'password',
//       key: 'password_confirmation',
//       templateOptions: {
//         matFormFieldClass: 'x--confirm-password',
//         required: true,
//         transloco: true,
//         label: 'USER.REGISTER.CONFIRM_PASSWORD',
//         placeholder: 'USER.REGISTER.CONFIRM_PASSWORD',
//         flex: {
//           fxFlex: '100%',
//           fxFlex__gt_sm: '50%',
//         },
//         passwordType: 'password',
//       },
//       validators: {
//         mismatchedPasswords: {
//           expression: (c, field) => {
//             return isNil(mismatchedPassword(field.form.get('password'))(c));
//           },
//         },
//       },
//     },
//   ],
// });
export const userRegistrationNameFormFragmentDynamicConfig: DynamicFormConfigInterface = dynamicFormConfigHelper(
  DynamicFormConfigEnum.USER_REGISTRATION_NAME_FORM_FRAGMENT,
  registrationFragment
);

export const companyMemberNameFormFragmentDynamicConfig: DynamicFormConfigInterface = dynamicFormConfigHelper(
  DynamicFormConfigEnum.COMPANY_MEMBER_NAME_FORM_FRAGMENT,
  generateFormlyFieldConfig(
    {
      key: 'last_name',
      matFormFieldClass: 'w-100',
      label: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.LAST_NAME.LABEL',
      placeholder: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.LAST_NAME.PLACEHOLDER',
    },
    {
      key: 'first_name',
      matFormFieldClass: 'w-100',
      label: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.FIRST_NAME.LABEL',
      placeholder: 'COMPANY_MEMBER.DETAILS.DATA_FORM.FIELD.FIRST_NAME.PLACEHOLDER',
    }
  )
);
