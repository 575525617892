import { State, Store } from '@ngxs/store';
import {
  detectMenuRefreshRemoteSuccessActionFn,
  detectUserStateKeyRefreshActionFn,
  detectUserStateSuccessActionFn,
  PERIOD_CONTEXT_STATE_NAME,
  periodContextRefreshActionFn,
  PeriodContextRefreshRemoteStateAction,
  PeriodContextRefreshRemoteStateErrorAction,
  periodContextRefreshRemoteStateErrorActionFn,
  PeriodContextRefreshRemoteStateSuccessAction,
  periodContextRefreshSuccessActionFn,
  PeriodContextRemoveVehicleAction,
  periodContextRemoveVehicleActionFn,
  PeriodContextService,
  PeriodContextStartVehiclePullAction,
  periodContextStartVehiclePullActionFn,
  PeriodContextStopVehiclePullAction,
  periodContextStopVehiclePullActionFn,
} from '@roadrecord/period-context/common';
import { PeriodContextStateUsModel } from './model/period-context-state-us.model';
import { Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { STATE_PREFIX_TOKEN } from '@roadrecord/company-context/common';
import { attachAction } from '@ngxs-labs/attach-action';
import { GetUserStatesSuccessAction, UserStateKeyRefreshAction } from '@roadrecord/common/common';
import { PeriodContextUsModel } from './model/period-context-us.model';

@State<PeriodContextStateUsModel>({
  name: PERIOD_CONTEXT_STATE_NAME,
  defaults: { runVehiclePull: false, notInited: true, version: 3 } as any,
})
@Injectable()
export class PeriodContextUsState {
  constructor(injector: Injector, ngZone: NgZone, router: Router, store: Store) {
    const prefix = injector.get<string>(STATE_PREFIX_TOKEN);
    const periodContextService = injector.get(PeriodContextService);

    attachAction(
      PeriodContextUsState,
      PeriodContextRefreshRemoteStateAction,
      periodContextRefreshActionFn<PeriodContextUsModel, PeriodContextStateUsModel>(periodContextService)
    );

    attachAction<PeriodContextStateUsModel, PeriodContextRefreshRemoteStateSuccessAction>(
      PeriodContextUsState,
      PeriodContextRefreshRemoteStateSuccessAction,
      periodContextRefreshSuccessActionFn
    );

    attachAction<PeriodContextStateUsModel, PeriodContextRefreshRemoteStateErrorAction>(
      PeriodContextUsState,
      PeriodContextRefreshRemoteStateErrorAction,
      periodContextRefreshRemoteStateErrorActionFn
    );

    /**
     * a menu -bol szuksegunk van az invalid period context allapot detect-re
     */
    attachAction(
      PeriodContextUsState,
      // hack circular dep miatt
      { type: '[Menu] Refresh remote state Success' },
      detectMenuRefreshRemoteSuccessActionFn(ngZone, router, store, prefix)
    );

    attachAction(PeriodContextUsState, GetUserStatesSuccessAction, detectUserStateSuccessActionFn(ngZone, router, store, prefix));

    attachAction(PeriodContextUsState, UserStateKeyRefreshAction, detectUserStateKeyRefreshActionFn(prefix));

    attachAction<PeriodContextStateUsModel, PeriodContextRemoveVehicleAction>(
      PeriodContextUsState,
      PeriodContextRemoveVehicleAction,
      periodContextRemoveVehicleActionFn
    );
    attachAction<PeriodContextStateUsModel, PeriodContextStartVehiclePullAction>(
      PeriodContextUsState,
      PeriodContextStartVehiclePullAction,
      periodContextStartVehiclePullActionFn
    );
    attachAction<PeriodContextStateUsModel, PeriodContextStopVehiclePullAction>(
      PeriodContextUsState,
      PeriodContextStopVehiclePullAction,
      periodContextStopVehiclePullActionFn
    );
  }
}
