import { RRFormWithDynamicFormBottomSheetConfig } from '@roadrecord/utils';
import { Injector } from '@angular/core';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { throwNotOverrideError } from '@roadrecord/common/common';
import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * FILE REPLACES
 */

/**
 * Average fuel bottom sheet -be a form mezoket es a translatet ezen configok alapjan generaljuk
 */

export function searchMissingFieldConfig(
  bottomSheetData: RRFormWithDynamicFormBottomSheetConfig<VehicleModel>,
  injector: Injector
): FormlyFieldConfig[] {
  throw throwNotOverrideError('searchMissingFieldConfig');
}

export const searchMissingFieldTranslate: { title: string; content: string } = null;
