import { MaskedNumber } from 'imask';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

const numberMaskConfig1 = { scale: 2, max: 999 };
const numberMaskConfig2 = { scale: 0, max: 999999 };

export function getEditCellNumberMaskConfig(): { cost: MaskedNumber; quantity: MaskedNumber; mileage: MaskedNumber } {
  return {
    cost: globalImaskNumberConfigGenerator(numberMaskConfig1),
    quantity: globalImaskNumberConfigGenerator(numberMaskConfig1),
    mileage: globalImaskNumberConfigGenerator(numberMaskConfig2),
  };
}
