export enum FuelTypeUsEnum {
  GASOLINE_ = 'GASOLINE',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  DIESEL_HYBRID = 'DIESEL_HYBRID',
  ELECTRIC = 'ELECTRIC',
  LPG = 'LPG',
  // MIX = 'MIX',
  // MOTOR_BICYCLE_95 = '95_MOTOR_BICYCLE'
}
