<ng-template #formTpl>
  <form [formGroup]="form" novalidate fxLayout="column" fxLayoutAlign="center stretch" (keyup.enter)="onSubmit()">
    <div fxLayout="column" fxLayout.gt-sm="row">
      <mat-form-field class="wid100">
        <mat-label>{{ 'TRIP_REASON.DETAILS.DATA_FORM.FIELD.TRIP_REASON.LABEL' | transloco }}</mat-label>
        <input
          formControlName="trip_reason"
          name="trip_reason"
          matInput
          type="text"
          rrFocusInput="true"
          rrFocusDelay="300"
          rrCapitalize
          [placeholder]="'TRIP_REASON.DETAILS.DATA_FORM.FIELD.TRIP_REASON.PLACE_HOLDER' | transloco"
          required
        />
        <mat-error> <rr-validation-messages [errors]="tripReasonControl?.errors"></rr-validation-messages> </mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row">
      <rr-two-way-switch-form-control
        option_false="TRIP_REASON.DETAILS.DATA_FORM.FIELD.IS_PRIVATE.LABELS.BUSINESS"
        option_true="TRIP_REASON.DETAILS.DATA_FORM.FIELD.IS_PRIVATE.LABELS.PRIVATE"
        icon_name_false="briefcase"
        icon_name_true="human-handsdown"
        name="is_private"
        formControlName="is_private"
        fxFlex="100%"
        fxFlex.gt-md="50%"
      ></rr-two-way-switch-form-control>
      <div style="display: flex; flex-direction: column; width: 100%"
        ><div style="display: flex; flex-direction: row; justify-content: flex-end"
          ><mat-checkbox
            class="text-right"
            labelPosition="before"
            name="is_default"
            formControlName="is_default"
            fxFlex="100%"
            fxFlex.gt-md="50%"
          >
            {{ 'TRIP_REASON.DETAILS.DATA_FORM.FIELD.IS_DEFAULT.LABEL' | transloco }} </mat-checkbox
          ><mat-icon
            style="align-self: center; margin-left: 8px; cursor: default"
            color="accent"
            svgIcon="information-outline"
            [matTooltip]="'TRIP_REASON.DEFAULT_DESCRIPTION' | transloco"
          ></mat-icon></div
      ></div>
    </div>
  </form>
</ng-template>

<ng-template #buttonsTpl>
  <rr-details-button-row
    [isNew]="isNew"
    [disabled]="(form['submitted'] && form.invalid) || form.disabled"
    [form]="form"
    [hasSubmitAndNew]="hasSubmitAndNew"
    [hasDelete]="hasDelete"
    [disableDelete]="false"
    (delete)="onDelete()"
    (submitForm)="onSubmit()"
    (submitAndNew)="onSubmitReset()"
  ></rr-details-button-row>
</ng-template>
