import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@ngneat/transloco';
import { AppTypePipeModule, SanitizePipeModule, TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { ProgressWhenFirstLoadingModule } from '@roadrecord/progress-when-first-loading';
import { ValidatingModule } from '@roadrecord/validating';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FirstStepsRoutingModule } from './../../../common/src/lib/first-steps-routing.module';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FinishMobileLayoutComponent } from './../../../common/src/lib/layouts/finish-mobile-layout/finish-mobile-layout.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FinishWelcomeComponent } from './../../../common/src/lib/layouts/finish-welcome/finish-welcome.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { StartWelcomeComponent } from './../../../common/src/lib/layouts/start-welcome/start-welcome.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { SteppingComponent } from './../../../common/src/lib/layouts/stepping/stepping.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { PartnerWrapperStepModule } from './../../../common/src/lib/layouts/stepping/steps/partner-wrapper-step/partner-wrapper-step.module';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { VehicleWrapperStepComponent } from './../../../common/src/lib/layouts/stepping/steps/vehicle-wrapper-step/vehicle-wrapper-step.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { WrapperComponent } from './../../../common/src/lib/wrapper/wrapper.component';
import { LazyDetailsModule } from '@roadrecord/lazy-details';
import { PriceModule, RegisterModule } from '@roadrecord/user/common';
// tslint:disable-next-line:nx-enforce-module-boundaries; nx-enforce-module-boundaries
import { RegisterWrapperStepComponent } from './../../../common/src/lib/layouts/stepping/steps/register-wrapper-step/register-wrapper-step.component';
import { DynamicFormModule } from '@roadrecord/dynamic-form';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormModule } from '@roadrecord/utils';
// tslint:disable-next-line:nx-enforce-module-boundaries; nx-enforce-module-boundaries
import { FirstStepperHelpBoxModule } from '../../../common/src/lib/layouts/stepping/first-stepper-help-box/first-stepper-help-box.module';
// tslint:disable-next-line:nx-enforce-module-boundaries; nx-enforce-module-boundaries
import { ButtonCardComponent } from '../../../common/src/lib/layouts/finish-welcome/button-card/button-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ValidatingModule,
    MatFormFieldModule,

    FirstStepsRoutingModule,

    FlexLayoutModule,

    MatStepperModule,
    MatButtonModule,
    MatCardModule,
    // MatSnackBarModule,
    MatIconModule,
    LazyDetailsModule,
    ProgressWhenFirstLoadingModule,
    PartnerWrapperStepModule,
    TranslocoModule,
    SanitizePipeModule,
    AppTypePipeModule,
    RegisterModule,
    DynamicFormModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FormModule,
    TextPlaceholderHtmlTagReplacerModule,
    FirstStepperHelpBoxModule,
    AppTypePipeModule,
    PriceModule,
  ],
  declarations: [
    SteppingComponent,
    StartWelcomeComponent,
    FinishWelcomeComponent,
    WrapperComponent,
    VehicleWrapperStepComponent,
    RegisterWrapperStepComponent,
    FinishMobileLayoutComponent,
    ButtonCardComponent,
    ButtonCardComponent,
  ],
})
export class FirstStepsModule {}
