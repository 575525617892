import { LiveAutoCompleteOptionsConfigModel } from '@roadrecord/live-auto-complete';
import { addressFormatter } from './address-formatter.function';
import { laPartnerOptionsConfig } from '@roadrecord/partner/live-auto-complete';
import { PartnerListUsModel } from '../model/partner-list-us.model';

function addressFn(model: PartnerListUsModel) {
  return `${addressFormatter(model)}`;
}

function displayFn(model: PartnerListUsModel) {
  return `${model.name} (${addressFn(model)})`;
}

export const laPartnerOptionsConfigUs = new LiveAutoCompleteOptionsConfigModel<PartnerListUsModel>({
  ...laPartnerOptionsConfig,
  displayFn,
  optionDisplayFn: displayFn,
  lazyOptionElem: {
    ...laPartnerOptionsConfig.lazyOptionElem,
    options: {
      ...laPartnerOptionsConfig.lazyOptionElem.options,
      addressTextCb: model => addressFn(model),
    },
  },
});
