import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { PayoffTypeEnum } from '@roadrecord/vehicle/model/us';

/**
 * Azert van szukseg, mert csak bizonyos folyamatoknal kell mw-n behazudni SMR eseten,
 * hogy self employed es private
 */
export function isPrivatePeriodContextFn(context: any, periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>) {
  return context.vehicle.payoff_type === PayoffTypeEnum.SMR;
}
