import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TranslocoService } from '@ngneat/transloco';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION } from '@roadrecord/dynamic-form';
import { DOCUMENT } from '@angular/common';
import {
  AbstractRRFormWithDynamicForm,
  BottomSheetRRFormWithDynamicForm,
  getBottomSheetComponentBase,
  RRFormWithDynamicFormBottomSheetConfig,
} from '@roadrecord/utils';
import { searchMissingFieldConfig } from '../details/search-missing-field.config';
import { VehicleUsModel } from '@roadrecord/vehicle/model/us';

@Component({
  selector: 'rr-real-fuel-bottom-sheet',
  templateUrl: './real-fuel-bottom-sheet.component.html',
  styleUrls: ['./real-fuel-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
    },
  ],
})
export class RealFuelBottomSheetComponent
  extends getBottomSheetComponentBase<VehicleUsModel, RealFuelBottomSheetComponent>()
  implements AbstractRRFormWithDynamicForm<VehicleUsModel>, BottomSheetRRFormWithDynamicForm<VehicleUsModel>, OnInit, AfterViewInit {
  constructor(
    bottomSheetRef: MatBottomSheetRef<RealFuelBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: RRFormWithDynamicFormBottomSheetConfig<VehicleUsModel>,
    @Inject(DOCUMENT) document: Document,
    translocoService: TranslocoService,
    messageDialogService: MessageDialogService,
    injector: Injector
  ) {
    super(injector, document, translocoService, messageDialogService, null, bottomSheetRef, data);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  setFormFields() {
    return searchMissingFieldConfig(this.bottomSheetData, this.injector);
  }

  onSubmit(formValue: VehicleUsModel) {
    super.onSubmit({
      ...formValue,
      real_fuel_unit: this.formlyFields[0].fieldGroup[0].templateOptions.realFuelUnitControl.value,
    });
  }
}
