import { FuelingSettings } from '@roadrecord/fueling/common';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';

const numberMaskConfig1 = { scale: 2, max: 999 };
const numberMaskConfig2 = { scale: 0, max: 999999 };

export const fuelingSettings: FuelingSettings = {
  warning: {
    quantity: { common: 36, electric: 100 },
    cost: 90,
  },
  mask: {
    cost: globalImaskNumberConfigGenerator(numberMaskConfig1),
    quantity: globalImaskNumberConfigGenerator(numberMaskConfig1),
    distance: globalImaskNumberConfigGenerator(numberMaskConfig2),
    mileage: globalImaskNumberConfigGenerator({ ...numberMaskConfig2, scale: 1 }),
  },
};
