/**
 * File replacement
 */
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ExtraButtonsConfig } from '../../../../common/src/lib/model/extra-buttons.config';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ReportPrintService } from '../../../../common/src/lib/report-print.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '@roadrecord/http-error-interceptor';
import { MaybeHandleHttpError, openWindowOrMessageDialog } from '@roadrecord/utils';
import { WINDOW } from '@roadrecord/common/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import { Observable, timer } from 'rxjs';
import { NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from '@roadrecord/type-guard';
import { environment } from '@roadrecord/environment';

const translatePrefix = 'REPORT_PRINT.FRESHBOOK.';
const createTranslateKey = (key: string) => `${translatePrefix}${key}`;
const translate = (key: string, translocoService: TranslocoService) => translocoService.translate(createTranslateKey(key));

export const extraButtonsConfig: ExtraButtonsConfig = {
  items: [
    {
      svgIcon: 'freshbook-logo',
      wrapperClass: 'freshbook',
      tooltip: 'REPORT_PRINT.PDF.TOOLTIP.FRESHBOOK',
      click: injector => {
        // Get from DI
        const window = injector.get(WINDOW) as Window;
        const translocoService = injector.get(TranslocoService) as TranslocoService;
        const matSnackBar = injector.get(MatSnackBar) as MatSnackBar;
        const httpErrorInterceptor = injector
          .get(HTTP_INTERCEPTORS)
          .find(httpInterceptor => httpInterceptor instanceof HttpErrorInterceptor) as HttpErrorInterceptor;

        // Create custom observer
        return new Observable(observer => {
          httpErrorInterceptor.addDisableErrorCode(469);
          const startSnackBarRef = matSnackBar.open(translate('SNACKBAR.START', translocoService), undefined, { duration: 9999999 });
          // Start freshbook generate report
          injector
            .get(ReportPrintService)
            .generateFreshbookReport()
            .subscribe(
              () => {
                matSnackBar.open(translate('SNACKBAR.SUCCESS', translocoService));
                httpErrorInterceptor.removeDisableErrorCode(469);
                observer.next();
                observer.complete();
              },
              error => {
                httpErrorInterceptor.removeDisableErrorCode(469);
                if (error.status === 469) {
                  startSnackBarRef.dismiss();
                  MessageDialogService.INSTANCE.openInformation({
                    id: null,
                    title: createTranslateKey('NOT_FOUND_CONNECTION_DIALOG.TITLE'),
                    text: createTranslateKey('NOT_FOUND_CONNECTION_DIALOG.TEXT'),
                    confirmLabel: createTranslateKey('NOT_FOUND_CONNECTION_DIALOG.LABEL.CONFIRM'),
                    enableCancel: true,
                    cancelLabel: createTranslateKey('NOT_FOUND_CONNECTION_DIALOG.LABEL.CANCEL'),
                  })
                    .afterClosed()
                    .subscribe(result => {
                      if (result.result) {
                        const ngZone = injector.get(NgZone);
                        let programmaticallyClosed = false;
                        ngZone.runOutsideAngular(() => {
                          (window as any).programmaticallyCloseFreshBookWindow = () => {
                            programmaticallyClosed = true;
                          };

                          const windowUrl =
                            environment.production === false
                              ? /*'http://localhost:5000/assets/social/close-window.html'*/ `http://localhost:9002/api/v1/auth/social/freshbooks/start/`
                              : `${environment.apiUrl}auth/social/freshbooks/start/`;

                          const newWin = openWindowOrMessageDialog({
                            linkName: translate('POPUP_BLOCK_LINK_NAME', translocoService),
                            linkUrl: windowUrl,
                            windowTarget: '_blank',
                            windowParams: 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=600,height=800',
                          });

                          if (!isNil(newWin)) {
                            const timerSubscription = timer(1000, 1500).subscribe(() => {
                              if (newWin.closed) {
                                timerSubscription.unsubscribe();
                                delete (window as any).programmaticallyCloseFreshBookWindow;
                                ngZone.run(() => {
                                  // cdr miatt kell a timer
                                  timer(0).subscribe(() =>
                                    matSnackBar.open(
                                      translate(`SNACKBAR.${programmaticallyClosed ? 'PROGRAM_CLOSE' : 'USER_CLOSE'}`, translocoService)
                                    )
                                  );
                                  observer.next(programmaticallyClosed);
                                  observer.complete();
                                });
                              }
                            });
                          } else {
                            // Popup nem tudott kinyilni, ezert lezarjuk az esemenyt
                            observer.next(false);
                            observer.complete();
                          }
                        });
                      } else {
                        // cancel
                        observer.next(false);
                        observer.complete();
                      }
                    });
                } else {
                  MaybeHandleHttpError.maybeHandleHttpError(error, () => {
                    observer.next(false);
                    observer.complete();
                  });
                }
              }
            );
        });
      },
      extraStyles: `.freshbook {
                      padding-left: 0 !important;
                      padding-right: 0 !important;
                    }
                    .freshbook .mat-icon {
                      width: 98px !important;
                    }
                    .freshbook .mat-icon svg {
                      width: 98px !important;
                    }`,
    },
  ],
};
