import { PayoffTypeEnum } from './payoff-type.enum';
import { RealFuelTypeEnum } from './real-fuel-type.enum';
import { VehicleUsModel } from './vehicle-us.model';

export class VehicleResetUsModel implements VehicleUsModel {
  departure = undefined;
  fuel_type = undefined;
  id = undefined;
  manufacturer = undefined;
  model = undefined;
  icon = '';
  payoff_type = PayoffTypeEnum.SMR;
  plate_number = undefined;
  real_fuel = '' as any;
  vehicle_driver = undefined;
  vehicle_owner = undefined;
  calculated_average_fuel = undefined;
  real_fuel_unit = RealFuelTypeEnum.MPG;
  count_of_payment_transactions = 0;
  has_available_subscription = false;
  subscriptions = [];
  meta = {
    plate_number_can_be_modified: false,
  };
  is_departure_used = false;
}
