import { Store } from '@ngxs/store';
import { PeriodContextStateSelectorsService } from '@roadrecord/period-context/common';
import { PayoffTypeEnum } from '@roadrecord/vehicle/model/us';

export function generateRecommendationButtonTooltip(
  store: Store,
  periodContextStateSelectorsService: PeriodContextStateSelectorsService<any, any>,
  periodContext: any
) {
  if (periodContext.vehicle.payoff_type === PayoffTypeEnum.AEM) {
    return 'COMPANY';
  } else {
    return 'PRIVATE';
  }
}
