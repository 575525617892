import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ENTITY_SERVICE_TOKEN } from '@roadrecord/utils';
// @ts-ignore
import { FirstStepsComponentTypeEnum } from '../../../../model/first-steps-component-type.enum';
import { VehicleModel } from '@roadrecord/vehicle/model/common';
import { FirstStepsHelpModel } from '@roadrecord/first-steps/common';
import { VehicleService } from '@roadrecord/vehicle/service/common';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { HttpClient } from '@angular/common/http';
import { isNil } from '@roadrecord/type-guard';

@Component({
  selector: 'rr-vehicle-wrapper-step',
  templateUrl: './vehicle-wrapper-step.component.html',
  styleUrls: ['./vehicle-wrapper-step.component.scss'],
  providers: [{ provide: ENTITY_SERVICE_TOKEN, useExisting: VehicleService }],
})
export class VehicleWrapperStepComponent {
  @Input()
  type: FirstStepsComponentTypeEnum;
  @Input()
  helpText: FirstStepsHelpModel;
  @Output()
  save = new EventEmitter<VehicleModel>();
  @Output() skipStep = new EventEmitter<void>();

  hasAvailableLicense = false;
  saveVehicleMode: VehicleModel;

  detailsHeight = '100%';
  detailsOptions = {
    routingMode: false,
    hasSubmitAndNew: false,
    hasBackButton: false,
    disableAllSelectNewOption: false,
    oneColumnMode: true,
  };

  constructor(private http: HttpClient) {}

  onSaveVehicle(vehicle: VehicleModel): void {
    this.saveVehicleMode = vehicle;
    this.save.emit(vehicle);
  }

  onDetailsEvents($event: { eventName: string; value: any }) {
    if ($event.eventName === 'save') {
      this.onSaveVehicle($event.value);
    }
  }

  onSkipStep() {
    this.skipStep.emit();
  }

  onBackToStepper(): void {
    if (isNil(this.saveVehicleMode)) {
      this.skipStep.emit();
    } else {
      this.save.emit(this.saveVehicleMode);
    }
  }
}
