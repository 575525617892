// tslint:disable-next-line:nx-enforce-module-boundaries
import { ImaskNumberConfig, ImaskNumberConfigInterface } from '../../../../common/src/lib/model/imask-number.config';
import { MaskedNumber } from 'imask';

/**
 * replaced file
 */

export function globalImaskNumberConfigGenerator(data?: Partial<ImaskNumberConfigInterface>): MaskedNumber {
  return new ImaskNumberConfig(Object.assign({ thousandsSeparator: ',', radix: '.', mapToRadix: [','] }, data || {}));
}
