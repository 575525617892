import { TranslocoService } from '@ngneat/transloco';
import { GridColumnModel } from '@roadrecord/grid';
import { TripReasonModel } from '@roadrecord/trip-reason/model';
import { listColumnConfigCommon } from './list-column.config-common';

export const listColumnConfig: (_this: { translocoService: TranslocoService }) => GridColumnModel<TripReasonModel>[] = (_this: {
  translocoService: TranslocoService;
}) => {
  const list = listColumnConfigCommon({ translocoService: _this.translocoService });
  list[2].cellMatIconCallback = model =>
    model.is_default
      ? { value: 'information-outline', cssClassName: 'light-color', tooltipTextTranslateKey: 'TRIP_REASON.DEFAULT_DESCRIPTION' }
      : null;

  return list;
};
