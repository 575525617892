import { isNil, isNotNilAndString } from '@roadrecord/type-guard';
import { PartnerListUsModel } from '../model/partner-list-us.model';

export function addressFormatter(model: PartnerListUsModel, hasPostCode = true) {
  const { address } = model;
  if (isNil(address)) {
    return '';
  }
  const strBuilder = [];

  if (isNotNilAndString(address.house_number)) {
    strBuilder.push(address.house_number);
  }
  if (isNotNilAndString(address.street)) {
    strBuilder.push(`${address.street},`);
  }
  strBuilder.push(`${address.city},`, address.state);

  if (hasPostCode === true) {
    strBuilder.push(`${address.postcode}`);
  }
  return strBuilder.join(' ');
}
