import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { isNil, isNumeric, isString } from '@roadrecord/type-guard';
import { Subscription, timer } from 'rxjs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Validators } from '@angular/forms';
import { DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION, dynamicFormConfigHelper } from '@roadrecord/dynamic-form';
import {
  commonHttpStreamErrorHandler,
  FieldHttpError,
  manualHandleFormControlsSetServerError,
  ManualViewRemoteFieldErrorPlugin,
} from '@roadrecord/utils';
import { TranslocoService } from '@ngneat/transloco';
import { DOCUMENT } from '@angular/common';
import { MessageDialogService } from '@roadrecord/message-dialog';
import {
  CapitalizeDirective,
  globalPasswordControlValidationMessages,
  mismatchedPassword,
  mismatchedRePassword,
  RRFormGroup,
} from '@roadrecord/common/common';
import { AreaOfInterestUSEnum, AuthService } from '@roadrecord/user/common';
import { PasswordValidators, UniversalValidators } from 'ngx-validators';
import { rrFormErrorStateMatcher } from '@roadrecord/validating';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { _phoneNumberValidator2 } from '@roadrecord/ngx-phone-validators';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { FirstStepsHelpModel } from '../../../../../../../common/src/lib/model/first-steps-next-step.model';

const minlength = Validators.minLength(8);
const maxlength = Validators.maxLength(20);
const uppercaseCharacterRule = PasswordValidators.uppercaseCharacterRule(1);
const lowercaseCharacterRule = PasswordValidators.lowercaseCharacterRule(1);
const digitCharacterRule = PasswordValidators.digitCharacterRule(1);
const noWhitespaceRequired = UniversalValidators.noWhitespace;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rr-register-wrapper-step',
  templateUrl: './register-wrapper-step.component.html',
  styleUrls: ['./register-wrapper-step.component.scss'],
  providers: [
    rrFormErrorStateMatcher,
    {
      // TODO ez ide tuti kell? mivel injectorral toltjuk be...
      provide: DISABLE_VIEW_REMOTE_FIELD_ERROR_FORMLY_EXTENSION,
      useValue: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterWrapperStepComponent implements OnInit {
  @Input()
  helpText: FirstStepsHelpModel;
  @Output()
  save = new EventEmitter<void>();
  form: RRFormGroup;
  formlyFields: FormlyFieldConfig[];
  formModel = {};
  private viewRemoteFieldErrorPlugin: ManualViewRemoteFieldErrorPlugin<any, any>;
  loaded = false;
  loading = false;
  formOptions: FormlyFormOptions = {
    formState: {
      disabled: false,
    },
  };
  showPassword = false;

  constructor(
    private injector: Injector,
    private translocoService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private messageDialogService: MessageDialogService,
    private authService: AuthService<any>,
    private cdr: ChangeDetectorRef
  ) {}

  private setDynamicForm(injector: Injector, showPassword) {
    this.showPassword = showPassword;

    let firstNameSubscription: Subscription;
    let lastNameSubscription: Subscription;
    const firstName = {
      key: 'last_name',
      matFormFieldClass: 'x--last-name',
      label: 'USER.REGISTER.LAST_NAME',
      placeholder: 'USER.REGISTER.EX_LAST_NAME',
    };
    const lastName = {
      key: 'first_name',
      matFormFieldClass: 'x--first-name',
      label: 'USER.REGISTER.FIRST_NAME',
      placeholder: 'USER.REGISTER.EX_FIRST_NAME',
    };
    const field: FormlyFieldConfig[] = [
      {
        type: 'flex-layout',
        templateOptions: {
          cssClass: 'w-100',
          flex: {
            fxLayout: 'column',
            fxLayout__gt_sm: 'row',
            fxLayoutGap: '0em',
            fxLayoutGap__gt_sm: '1em',
          },
        },
        fieldGroup: [
          {
            type: 'input',
            key: firstName.key,
            focus: true,
            templateOptions: {
              matFormFieldClass: firstName.matFormFieldClass,
              required: true,
              minLength: 2,
              maxLength: 40,
              transloco: true,
              label: firstName.label,
              placeholder: firstName.placeholder,
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
            hooks: {
              onInit: ({ formControl }) =>
                (firstNameSubscription = formControl.valueChanges
                  .pipe(
                    filter(() => formControl.enabled),
                    distinctUntilChanged()
                  )
                  .subscribe(v => formControl.patchValue(CapitalizeDirective.capitalizeText(v)))),
              onDestroy: () => firstNameSubscription.unsubscribe(),
            },
          },
          {
            type: 'input',
            key: lastName.key,
            templateOptions: {
              matFormFieldClass: lastName.matFormFieldClass,
              required: true,
              minLength: 2,
              maxLength: 40,
              transloco: true,
              label: lastName.label,
              placeholder: lastName.placeholder,
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
            hooks: {
              onInit: ({ formControl }) =>
                (lastNameSubscription = formControl.valueChanges
                  .pipe(
                    filter(() => formControl.enabled),
                    distinctUntilChanged()
                  )
                  .subscribe(v => formControl.patchValue(CapitalizeDirective.capitalizeText(v)))),
              onDestroy: () => lastNameSubscription.unsubscribe(),
            },
          },
        ],
      },
      {
        type: 'flex-layout',
        templateOptions: {
          cssClass: 'w-100',
          flex: {
            fxLayout: 'column',
            fxLayout__gt_sm: 'row',
            fxLayoutGap: '0em',
            fxLayoutGap__gt_sm: '1em',
          },
        },
        fieldGroup: [
          {
            type: 'input',
            key: 'company_name',
            templateOptions: {
              matFormFieldClass: 'x--company-name',
              transloco: true,
              label: 'FIRST_STEPS.COMPANY_DATA.COMPANY_NAME',
              placeholder: 'FIRST_STEPS.COMPANY_DATA.EX_COMPANY_NAME',
              description: 'FIRST_STEPS.COMPANY_DATA.EX_COMPANY_NAME',
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
          },
          {
            type: 'select',
            key: 'area_of_interest',
            templateOptions: {
              matFormFieldClass: 'x--area-of-interest',
              required: true,
              transloco: true,
              label: 'FIRST_STEPS.COMPANY_DATA.AREA_OF_INTEREST.LABEL',
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
              options: Object.entries(AreaOfInterestUSEnum)
                .filter(next => isNumeric(next[0]))
                .reduce((current, next) => {
                  current.push({
                    label: this.translocoService.translate(`FIRST_STEPS.COMPANY_DATA.AREA_OF_INTEREST.VALUE.${next[1]}`),
                    value: next[0],
                  });
                  return current;
                }, []),
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
          },
        ],
      },
      {
        type: 'flex-layout',
        templateOptions: {
          cssClass: 'w-100',
          flex: {
            fxLayout: 'column',
            fxLayout__gt_sm: 'row',
            fxLayoutGap: '0em',
            fxLayoutGap__gt_sm: '1em',
          },
        },
        fieldGroup: [
          {
            type: 'input',
            key: 'email',
            templateOptions: {
              matFormFieldClass: 'x--email',
              transloco: true,
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
              label: 'USER.REGISTER.EMAIL',
              placeholder: 'USER.REGISTER.EX_EMAIL',
              disabled: true,
            },
          },
          {
            type: 'input',
            key: 'phone_number',
            templateOptions: {
              matFormFieldClass: 'x--phone-number',
              transloco: true,
              label: 'FIRST_STEPS.COMPANY_DATA.PHONE_NUMBER',
              required: true,
              validationMessages: [
                {
                  errorKey: 'phoneNumber',
                  translateKey: 'COMMON.VALIDATION.PHONE.NO_PHONE_NUMBER',
                },
              ],
              numberMaskOptions: {
                mask: '{(}000{)}000{-}0000',
                lazy: false,
                parse: value =>
                  value.replace(new RegExp(/-/, 'g'), '').replace(new RegExp(/\(/, 'g'), '').replace(new RegExp(/\)/, 'g'), ''),
              },
              prefix: 'USER.REGISTER.PHONE_NUMBER_PREFIX',
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
            validators: {
              phoneNumber: {
                expression: _phoneNumberValidator2,
              },
            },
          },
        ],
      },
    ];
    if (showPassword) {
      field.push({
        type: 'flex-layout',
        templateOptions: {
          cssClass: 'w-100',
          flex: {
            fxLayout: 'column',
            fxLayout__gt_sm: 'row',
            fxLayoutGap: '0em',
            fxLayoutGap__gt_sm: '1em',
          },
        },
        fieldGroup: [
          {
            type: 'password',
            key: 'password',
            templateOptions: {
              matFormFieldClass: 'x--password',
              required: true,
              transloco: true,
              label: 'USER.REGISTER.PASSWORD',
              placeholder: 'USER.REGISTER.PASSWORD',
              description: 'COMMON.VALIDATION.PASSWORD_PATTERN',
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
              passwordType: 'password',
              validationMessages: globalPasswordControlValidationMessages,
            },
            validators: {
              minlength: {
                expression: c => isNil(minlength(c)),
              },
              maxlength: {
                expression: c => isNil(maxlength(c)),
              },
              uppercaseCharacterRule: {
                expression: c => isNil(uppercaseCharacterRule(c)),
              },
              lowercaseCharacterRule: {
                expression: c => isNil(lowercaseCharacterRule(c)),
              },
              digitCharacterRule: {
                expression: c => isNil(digitCharacterRule(c)),
              },
              noWhitespaceRequired: {
                expression: c => isNil(noWhitespaceRequired(c)),
              },
              mismatchedPasswords: {
                expression: (c, _field) => {
                  return isNil(mismatchedRePassword(_field.form.get('confirmed_password'))(c));
                },
              },
            },
            expressionProperties: {
              'templateOptions.hideDescription': (model: any, formState: any, _field: FormlyFieldConfig) => {
                return _field.formControl.valid || (isString(_field.formControl.value) && _field.formControl.value.length > 0);
              },
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
          },
          {
            type: 'password',
            key: 'confirmed_password',
            templateOptions: {
              matFormFieldClass: 'x--confirm-password',
              required: true,
              transloco: true,
              label: 'USER.REGISTER.CONFIRM_PASSWORD',
              placeholder: 'USER.REGISTER.CONFIRM_PASSWORD',
              flex: {
                fxFlex: '100%',
                fxFlex__gt_sm: '50%',
              },
              passwordType: 'password',
              validationMessages: globalPasswordControlValidationMessages,
            },
            expressionProperties: {
              // apply expressionProperty for disabled based on formState
              'templateOptions.disabled': 'formState.disabled',
            },
            validators: {
              mismatchedPasswords: {
                expression: (c, _field) => {
                  return isNil(mismatchedPassword(_field.form.get('password'))(c));
                },
              },
            },
          },
        ],
      });
    }
    field.push({
      type: 'flex-layout',
      templateOptions: {
        cssClass: `w-100 simple-checkbox${showPassword ? ' mt-4' : ''}`,
        flex: {
          fxLayout: 'column',
          fxLayout__gt_sm: 'row',
          fxLayoutGap: '0em',
          fxLayoutGap__gt_sm: '1em',
        },
      },
      fieldGroup: [
        {
          type: 'checkbox',
          key: 'is_magazine',
          defaultValue: false,
          templateOptions: {
            transloco: true,
            indeterminate: false,
            label: 'FIRST_STEPS.COMPANY_DATA.SUBSCRIBE_MAGAZINE',
          },
          expressionProperties: {
            // apply expressionProperty for disabled based on formState
            'templateOptions.disabled': 'formState.disabled',
          },
        },
      ],
    });
    this.formlyFields = dynamicFormConfigHelper('x' as any, field).config(injector);
  }

  ngOnInit() {
    this.form = new RRFormGroup({});

    this.initData();
  }

  private initData() {
    this.authService.getCompanyRegistrationCompleteDatasV2().subscribe(response => {
      this.setDynamicForm(this.injector, response.meta.password_required);
      this.loaded = true;
      this.cdr.markForCheck();
      this.finishInitData(response);
    }, commonHttpStreamErrorHandler());
  }

  private finishInitData(response: { meta: { password_required: boolean }; value: any }) {
    if (Object.keys(this.form.controls).length === 0) {
      return timer(500).subscribe(() => this.finishInitData(response));
    }
    this.form.patchValue(response.value);
    this.setManualViewRemoteField();
  }

  setManualViewRemoteField() {
    this.viewRemoteFieldErrorPlugin = new ManualViewRemoteFieldErrorPlugin(this.translocoService, this.document, this.messageDialogService);
    this.viewRemoteFieldErrorPlugin.formGroupLastValue = this.form;
  }

  private onSave(): void {
    this.save.emit();
  }

  onSubmit() {
    this.form.submitted = true;
    if (this.form.valid) {
      this.loading = true;
      const formValue = { ...this.formModel };
      this.formOptions.formState.disabled = true;
      this.cdr.markForCheck();
      this.authService.saveCompanyRegistrationCompleteDatas(formValue).subscribe(
        () => this.onSave(),
        commonHttpStreamErrorHandler(error => {
          this.loading = false;
          this.formOptions.formState.disabled = false;
          if (error instanceof FieldHttpError) {
            manualHandleFormControlsSetServerError(error, this.viewRemoteFieldErrorPlugin);
          }
          this.cdr.markForCheck();
        })
      );
    }
  }
}
