import { Component } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FuelTypeEnum } from '@roadrecord/vehicle/model/common';
import { FieldType } from '@ngx-formly/core';
import { globalImaskNumberConfigGenerator } from '@roadrecord/imask/common';
import { RealFuelTypeEnum } from '@roadrecord/vehicle/model/us';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'rr-us-real-fuel-field',
  template: `
    <div style="display: flex"
      ><mat-form-field class="w-100">
        <mat-label>{{ 'VEHICLE.DETAILS.DATA_FORM.LABEL.REAL_FUEL_CONSUMPTION' | transloco }}</mat-label>
        <input
          [placeholder]="'VEHICLE.DETAILS.DATA_FORM.LABEL.REAL_FUEL_CONSUMPTION' | transloco"
          [imask]="
            to.fuelType !== FuelTypeEnum.ELECTRIC && to.realFuelUnitControl?.value === RealFuelTypeEnum.MPG ? numberMask1 : numberMask2
          "
          unmask="typed"
          [formControl]="formControl"
          matInput
          [name]="key"
          required
          type="text"
        />
        <span matSuffix
          ><ng-container *ngIf="to.fuelType !== FuelTypeEnum.ELECTRIC; else electricUnitTpl"
            ><mat-select *ngIf="to.fuelType" class="real-fuel-unit-control" rrPreventClick [formControl]="to.realFuelUnitControl"
              ><mat-option [value]="RealFuelTypeEnum.GAL100">{{ 'COMMON.UNIT.LITER_PER_100KM' | transloco }}</mat-option>
              <mat-option [value]="RealFuelTypeEnum.MPG">{{ 'COMMON.UNIT.MPG' | transloco }}</mat-option></mat-select
            ></ng-container
          ><ng-template #electricUnitTpl>{{ 'COMMON.UNIT.KWH_PER_100KM' | transloco }}</ng-template></span
        ><mat-error><rr-validation-messages [errors]="formControl?.errors"></rr-validation-messages></mat-error> </mat-form-field
      ><mat-icon
        style="align-self: center; margin-left: 8px; cursor: default"
        color="accent"
        svgIcon="information-outline"
        [matTooltip]="'VEHICLE.DETAILS.DATA_FORM.TOOLTIP.REAL_FUEL_CONSUMPTION' | transloco"
      ></mat-icon
    ></div>
  `,
  styleUrls: ['./us-real-fuel.field.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UsRealFuelField }],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsRealFuelField extends FieldType {
  FuelTypeEnum = FuelTypeEnum;
  RealFuelTypeEnum = RealFuelTypeEnum;

  readonly numberMask1 = globalImaskNumberConfigGenerator({
    max: 999,
    scale: 0,
  });

  readonly numberMask2 = globalImaskNumberConfigGenerator({
    max: 999,
    scale: 2,
  });

  get type() {
    return this.to.type || 'text';
  }
}
