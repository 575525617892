import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngxs/store';

import { fadeInAnimation } from '@roadrecord/common/common';
import { PricePaymentSessionStorageEnum, UserModel } from '@roadrecord/user/common';
import { Observable } from 'rxjs';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { StartWelcomeComponentCommon } from './../../../../../common/src/lib/layouts/start-welcome/start-welcome-component.common';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { NextStepConfig } from '../../../../../common/src/lib/next-step.config';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Router } from '@angular/router';

/**
 * file replacement
 */

enum StepperPriceLayoutEnum {
  STEPPER = 0,
  PRICE_LIST = 1,
  PAYMENT = 2,
  ARRANGEMENT_FORM,
}

@Component({
  selector: 'rr-start-welcome',
  templateUrl: './start-welcome.component.html',
  styleUrls: ['./start-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('list', [transition(':enter', [query('@items', stagger(300, animateChild()))])]),
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }), // initial
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
      ]),
    ]),
    fadeInAnimation,
  ],
})
export class StartWelcomeComponent extends StartWelcomeComponentCommon implements OnChanges, OnDestroy {
  readonly user$: Observable<UserModel>;

  @Output()
  readonly nextStep = new EventEmitter<NextStepConfig>();

  stepperPriceLayout: StepperPriceLayoutEnum = StepperPriceLayoutEnum.STEPPER;
  readonly StepperPriceLayoutEnum = StepperPriceLayoutEnum;

  constructor(
    translocoService: TranslocoService,
    store: Store,
    http: HttpClient,
    router: Router,
    cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) document: Document,
    gtmService: GoogleTagManagerService
  ) {
    super(translocoService, store, router, http, cdr, document, gtmService);
    if (sessionStorage.getItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_RESPONSE)) {
      this.stepperPriceLayout = StepperPriceLayoutEnum.PAYMENT;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  startSteps(): void {
    this.gtmService.pushTag({
      event: `Click START_STEPS`,
      label: 'FirstWelcomeChoiceClick',
      category: 'FirstStepper',
    });
    this.nextStep.emit({ layoutName: 'START_WELCOME' });
  }

  onClickSkipAll() {
    this.gtmService.pushTag({
      event: `Click SKIP_ALL`,
      label: 'FirstWelcomeChoiceClick',
      category: 'FirstStepper',
    });
    this.nextStep.emit({ skipAll: true, layoutName: 'START_WELCOME' });
  }

  goToPriceList() {
    this.gtmService.pushTag({
      event: `Click GO_TO_PRICE_LIST`,
      label: 'FirstWelcomeChoiceClick',
      category: 'FirstStepper',
    });
    /*
    #KIADASHOZ-REGI
    goToOrderInANewTab(this.store.selectSnapshot(CompanyContextState.subscriptionState), this.http, this.translocoService);
*/
    this.stepperPriceLayout = StepperPriceLayoutEnum.PRICE_LIST;
  }

  onBackToStepper(): void {
    this.stepperPriceLayout = StepperPriceLayoutEnum.STEPPER;
  }

  onBackToPayment(success: boolean): void {
    if (success === true) {
      this.stepperPriceLayout = StepperPriceLayoutEnum.STEPPER;
    } else {
      this.stepperPriceLayout = StepperPriceLayoutEnum.PRICE_LIST;
    }
    sessionStorage.removeItem(PricePaymentSessionStorageEnum.PRICE_PAYMENT_RESPONSE);
  }
}
