<div
  ngClass.gt-sm="height100"
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="1em"
  class="w-100"
  [ngSwitch]="stepperPriceLayout"
  *ngIf="user$ | async as user"
>
  <div class="fix-content-area text-center" @fadeIn *ngSwitchCase="StepperPriceLayoutEnum.STEPPER">
    <h1>{{ 'FIRST_STEPS.START_WELCOME.HELLO' | transloco }}</h1>
    <p class="w-100 font-weight-bold text mt-3 mb-5" style="line-height: 32px"
      ><span [innerHTML]="'FIRST_STEPS.START_WELCOME.CONTENT' | transloco"></span
    ></p>
    <div class="d-flex flex-row align-items-center justify-content-center start-welcome-content" style="gap: 4rem">
      <div
        ><div><img src="assets/images/stepper/start-welcome/stepper_pricing.svg" (click)="goToPriceList()" style="cursor: pointer" /></div
        ><button
          id="go-to-price-list"
          data-cy="go-to-price-list"
          type="button"
          mat-raised-button
          class="mt-3 d-inline-block mx-auto start black-button"
          (click)="goToPriceList()"
          ><span>{{ 'FIRST_STEPS.START_WELCOME.PRICE_LIST' | transloco }}</span
          ><mat-icon svgIcon="play" style="margin-top: -2px; margin-left: 4px"></mat-icon></button
      ></div>
      <div
        ><div><img src="assets/images/stepper/start-welcome/start_setup.svg" (click)="startSteps()" style="cursor: pointer" /></div
        ><button
          id="start-steps"
          data-cy="start-steps"
          [disabled]="disableButton || (form.submitted && form.invalid)"
          type="button"
          mat-raised-button
          (click)="startSteps()"
          class="mt-3 d-block mx-auto start black-button"
          ><span>{{ 'FIRST_STEPS.START_WELCOME.START_BUTTON' | transloco }}</span
          ><mat-icon svgIcon="play" style="margin-top: -2px; margin-left: 4px; color: #ff645f"></mat-icon></button></div
      ><div
        ><div><img src="assets/images/stepper/start-welcome/skip_setup.svg" (click)="onClickSkipAll()" style="cursor: pointer" /></div
        ><button
          id="skip-all"
          data-cy="skip-all"
          [disabled]="disableButton || (form.submitted && form.invalid)"
          type="button"
          mat-raised-button
          (click)="onClickSkipAll()"
          class="mt-3 d-block mx-auto start black-button"
          ><span>{{ 'FIRST_STEPS.START_WELCOME.SKIP_STEPPER' | transloco }}</span
          ><mat-icon svgIcon="play" style="margin-top: -2px; margin-left: 4px"></mat-icon></button
      ></div>
    </div>
  </div>

  <div class="fix-content-area text-center" @fadeIn *ngSwitchCase="StepperPriceLayoutEnum.PRICE_LIST">
    <rr-order-layout [showInStepper]="true" (backToStepper)="onBackToStepper()"> </rr-order-layout>
  </div>

  <div class="fix-content-area text-center" @fadeIn *ngSwitchCase="StepperPriceLayoutEnum.PAYMENT">
    <rr-payment [showInStepper]="true" (backToStepper)="onBackToPayment($event)"> </rr-payment>
  </div>
</div>
