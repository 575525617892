import { DynamicFormConfigEnum, dynamicFormConfigHelper, DynamicFormConfigInterface } from '@roadrecord/dynamic-form';
import { FormlyFieldConfig } from '@ngx-formly/core';

const config: FormlyFieldConfig[] = [
  {
    type: 'flex-layout',
    templateOptions: {
      cssClass: 'w-100',
      flex: {
        fxLayout: 'column',
        fxLayout__gt_sm: 'row',
        fxLayoutGap: '1em',
      },
    },
    fieldGroup: [
      {
        type: 'input',
        key: 'house_number',
        templateOptions: {
          transloco: true,
          label: 'PARTNER.DETAILS.DATA_FORM.LABEL.HOUSE_NUMBER',
          placeholder: 'PARTNER.DETAILS.DATA_FORM.LABEL.HOUSE_NUMBER',
          flex: {
            fxFlex: '100%',
            fxFlex__gt_sm: '33%',
          },
        },
      },
      {
        type: 'input',
        key: 'street',
        templateOptions: {
          transloco: true,
          label: 'PARTNER.DETAILS.DATA_FORM.LABEL.STREET',
          placeholder: 'PARTNER.DETAILS.DATA_FORM.LABEL.STREET',
          flex: {
            fxFlex: '100%',
            fxFlex__gt_sm: '67%',
          },
        },
      },
    ],
  },
  {
    type: 'flex-layout',
    templateOptions: {
      cssClass: 'w-100',
      flex: {
        fxLayout: 'column',
        fxLayout__gt_sm: 'row',
        fxLayoutGap: '1em',
      },
    },
    fieldGroup: [
      {
        type: 'input',
        key: 'city',
        templateOptions: {
          required: true,
          transloco: true,
          label: 'PARTNER.DETAILS.DATA_FORM.LABEL.CITY',
          placeholder: 'PARTNER.DETAILS.DATA_FORM.LABEL.CITY',
          flex: {
            fxFlex: '100%',
            fxFlex__gt_sm: '33%',
          },
        },
      },
      {
        type: 'input',
        key: 'state',
        templateOptions: {
          required: true,
          transloco: true,
          label: 'PARTNER.DETAILS.DATA_FORM.LABEL.STATE',
          placeholder: 'PARTNER.DETAILS.DATA_FORM.LABEL.STATE',
          flex: {
            fxFlex: '100%',
            fxFlex__gt_sm: '33%',
          },
        },
      },
      {
        type: 'input',
        key: 'postcode',
        templateOptions: {
          required: true,
          transloco: true,
          label: 'PARTNER.DETAILS.DATA_FORM.LABEL.POSTCODE',
          placeholder: 'PARTNER.DETAILS.DATA_FORM.LABEL.POSTCODE',
          flex: {
            fxFlex: '100%',
            fxFlex__gt_sm: '33%',
          },
        },
      },
    ],
  },
];

export const formFragmentDynamicConfig: DynamicFormConfigInterface = dynamicFormConfigHelper(
  DynamicFormConfigEnum.PARTNER_ADDRESS_FORM_FRAGMENT,
  config
);
